import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Frame from "../components/Frame"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const { contactDetailsJson } = data
  return (
    <Frame
      currentPathname={location.pathname}
      contactDetails={contactDetailsJson}
    >
      <SEO title="404: Not Found" />
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Frame>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}
export default NotFoundPage

export const pageQuery = graphql`
  query {
    contactDetailsJson {
      email
      mobile
      landline
    }
  }
`
